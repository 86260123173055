@use "../variables" as vars;
@use "../mixins" as mixins;

.banner-slider {
    position: relative;
    width: 100%;
    // height: 56vw;
    // max-height: 100vh;
    @include mixins.responsive(height, vars.$banner_slider_height);
    @include mixins.responsive(max-height, vars.$banner_slider_max_height);
    @media (orientation: portrait) {
        min-height: 500px;
    }
    overflow: hidden;
    & .slides {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: map-get(vars.$colors, "black");
        & .slide {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: map-get(vars.$colors, "black");
            overflow: hidden;
            transform: translateX(100%);
            transition: transform 0.5s ease-out;
            &:last-child {
                transform: translateX(-100%);
            }
            &:first-child {
                transform: translateX(0);
            }
            & .slide-link {
                display: block;
                width: 100%;
                height: 100%;
            }
            & .slider-image {
                display: block;
                position: absolute;
                height: 100%;
                width: auto;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                & img {
                    display: block;  
                }
            }
            & .slider-text {
                position: absolute;
                /*color: map-get(vars.$colors, "light-grey");*/
                color: map-get(vars.$colors, "dark-grey");

                /*background-color: rgba(map-get(vars.$colors, "black"), 0.5);*/
                background-color: rgba(map-get(vars.$colors, "white"), 0.88);
                @include mixins.responsive(
                font-size,
                vars.$banner_slider_text_font_size
                );
                padding:.5rem 1rem;
                & p {
                    margin: 0;
                }
                & a {
                    color: map-get(vars.$colors, "light-grey");
                    &:hover,
                        &:focus {
                        color: map-get(vars.$colors, "white");
                    }
                }
                &.position-topleft {
                    top: 38.2%;
                    /*left: 2rem;*/
                    left:38.2%;
                    transform: translate(-50%, -50%);
                }
                &.position-topright {
                    top: 38.2%;
                    /*right: 2rem;*/
                    left:62.8%;
                    transform: translate(-50%, -50%);
                }
                &.position-bottomright {
                    bottom:   24%;
                    left:62.8%;
                    transform: translate(-50%, -50%);
                }
                &.position-bottomleft {
                    bottom:  24%;
                    left:38.2%;
                    transform: translate(-50%, -50%);
                }
                &.position-center {
                    top: 62.8%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &.position-middleleft {
                    top: 62.8%;
                    left:38.2%;
                    transform: translate(-50%, -50%);
                }

                &.position-middleright {
                    top: 62.8%;
                    left:62.8%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    & .slide-nav {
        position: absolute;
        bottom: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        & .slide-dot {
            display: inline-block;
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border:1px map-get(vars.$colors, "light-grey") solid;
            margin: 0 0.25rem;
            background-color:transparent;

            &.active {
                background-color: map-get(vars.$colors, "medium-grey");
            }
        }
    }
    & .banner-text {
        position: absolute;
        z-index: 2;
        bottom: 15%;
        left: 3rem;
        color: map-get(vars.$colors, "light-grey");
        background-color: rgba(map-get(vars.$colors, "black"), 0.5);
        @include mixins.responsive(font-size, vars.$banner_text_font_size);
    }
}

.general-info-wrapper {
    background-color: map-get(vars.$colors, "black");
    & .general-info {
        @include mixins.responsive(padding, vars.$general_info_paddings);
        display: flex;
        flex-wrap: wrap;
        & .info-block-wrapper {
            @include mixins.responsive-fixed-flex(
            vars.$info_block_wrapper_widths
            );
            @include mixins.responsive(
            padding,
            vars.$info_block_wrapper_paddings
            );
            & .info-block {
                @include mixins.responsive(padding, vars.$info_block_paddings);
                height: 100%;
                background-color: map-get(vars.$colors, "light-grey");
                & p {
                    line-height: 1.35;
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                & .info-block-button {
                    display: block;
                    width: 100%;
                    padding: 0.5rem 0;
                    text-align: center;
                    text-decoration: none;
                    background-color: map-get(vars.$colors, "black");
                    color: map-get(vars.$colors, "light-grey");
                    &:hover,
                        &:focus {
                        color: map-get(vars.$colors, "white");
                    }
                }
            }
        }
        & .column-project-categories {
            @include mixins.responsive-fixed-flex(
            vars.$column_project_categories_widths
            );
            display: flex;
            flex-wrap: wrap;
            & .project-category-block-wrapper {
                @include mixins.responsive-fixed-flex(
                vars.$project_category_block_wrapper_widths
                );
                @include mixins.responsive(
                height,
                vars.$project_category_block_wrapper_heights
                );
                @include mixins.responsive(
                padding,
                vars.$project_category_block_wrapper_paddings
                );
                &:nth-child(1) {
                    & .project-category-block {
                        background-color: map-get(vars.$colors, "yellow");
                        &:hover,
                            &:focus {
                            background-color: mix(
                                map-get(vars.$colors, "yellow"),
                                map-get(vars.$colors, "white")
                                );
                        }
                    }
                }
                &:nth-child(2) {
                    & .project-category-block {
                        background-color: map-get(vars.$colors, "orange");
                        color: map-get(vars.$colors, "light-grey");
                        &:hover,
                            &:focus {
                            background-color: mix(
                                map-get(vars.$colors, "orange"),
                                map-get(vars.$colors, "white"),
                                85%
                                );
                        }
                    }
                }
                &:nth-child(3) {
                    & .project-category-block {
                        background-color: map-get(vars.$colors, "blue");
                        color: map-get(vars.$colors, "light-grey");
                        &:hover,
                            &:focus {
                            background-color: mix(
                                map-get(vars.$colors, "blue"),
                                map-get(vars.$colors, "white"),
                                85%
                                );
                        }
                    }
                }
                &:nth-child(4) {
                    & .project-category-block {
                        background-color: map-get(vars.$colors, "light-grey");
                    }
                }
                & .project-category-block {
                    display: block;
                    text-decoration: none;
                    @include mixins.responsive(
                    padding,
                    vars.$project_category_block_paddings
                    );
                    height: 100%;
                    color: map-get(vars.$colors, "black");
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Poiret One;
                    @include mixins.responsive(
                    font-size,
                    vars.$project_category_block_font_size
                    );
                    & .project-category-name {
                        font-family: "Bebas Neue";
                        @include mixins.responsive(
                        font-size,
                        vars.$project_category_name_font_size
                        );
                        text-align: center;
                    }
                }
            }
        }
    }
}

.separator {
    background-color: map-get(vars.$colors, "black");
    & svg {
        width: 100%;
        height: auto;
        stroke: map-get(vars.$colors, "white");
        stroke-width: 2px;
    }
}

.project-categories-wrapper {
    & .project-category-wrapper {

        color: map-get(vars.$colors, "white");

        /*        @include mixins.responsive(
                transform,
                vars.$project_category_wrapper_scalings
                );*/

        @include mixins.responsive(
        padding,
        vars.$project_category_wrapper_padding
        );


        /*Workaround für vertikales Abstandsproblem durch scale;*/
        /*        @include mixins.responsive(
                margin-top,
                vars.$project_category_wrapper_margins
                );*/
        /******************************************************/
        &.project-category-wrapper-enactments {
            // padding-top: 1rem;
            color: map-get(vars.$colors, "yellow");
            background-color: map-get(vars.$colors, "black");
            & .projects-wrapper {
                & .project-wrapper  {
                    & .project {
                        &.description {
                            background-color: map-get(vars.$colors, "yellow");
                            color: map-get(vars.$colors, "black");
                            & .category-description-symbol {
                                right: 1rem;
                                bottom: 1rem;
                                width: 3rem;
                                & svg {
                                    width: 100%;
                                    height: auto;
                                    fill: black;
                                }
                            }
                        }
                        & .project-title {
                            color: map-get(vars.$colors, "yellow");
                        }
                    }
                }
                & .show-more-projects {
                    background-color: map-get(vars.$colors, "yellow");
                    &:hover,
                        &:focus {
                        background-color: darken(
                            map-get(vars.$colors, "yellow"),
                            10%
                            );
                    }
                }
            }
        }
        &.project-category-wrapper-sociocultural {
            background-color: map-get(vars.$colors, "black");
            & .project-category {
                & .project-category-headline,
                & .project-category-short-description {
                    text-align: right;
                    color: map-get(vars.$colors, "orange");
                }
            }
            & .projects-wrapper {
                & .project-wrapper {
                    & .project {
                        &.description {
                            background-color: map-get(
                                vars.$colors,
                                "orange"
                                );
                            color: map-get(vars.$colors, "black");
                            & .category-description-symbol {
                                left: 1rem;
                                bottom: 1rem;
                                width: 3rem;
                                & svg {
                                    width: 100%;
                                    height: auto;
                                    fill: map-get(vars.$colors, "black");
                                }
                            }
                        }

                        & .project-title, & .project-subtitle ,& .project-summary{
                            color: map-get(vars.$colors, "orange");
                        }

                    }

                }
                & .show-more-projects {
                    background-color: map-get(vars.$colors, "orange");
                    &:hover,
                        &:focus {
                        background-color: darken(
                            map-get(vars.$colors, "orange"),
                            10%
                            );
                    }
                }
            }
        }
        &.project-category-wrapper-culturaleducation {
            background-color: map-get(vars.$colors, "black");
            color: map-get(vars.$colors, "blue");
            & .projects-wrapper {
                & .project-wrapper {
                    & .project {
                        &.description {
                            background-color: map-get(vars.$colors, "blue");
                            color: map-get(vars.$colors, "black");
                            & .category-description-symbol {
                                left: 1rem;
                                top: 1rem;
                                width: 3rem;
                                & svg {
                                    width: 100%;
                                    height: auto;
                                    fill: map-get(vars.$colors, "black");
                                }
                            }
                        }
                        & .project-title {
                            color: map-get(vars.$colors, "blue");
                        }
                    }
                }
                & .show-more-projects {
                    background-color: map-get(vars.$colors, "blue");
                    &:hover,
                        &:focus {
                        background-color: darken(
                            map-get(vars.$colors, "blue"),
                            10%
                            );
                    }
                }
            }
        }
        & .project-category {
            & .project-category-headline {
                margin: 0 .33rem 0 .33rem;
                font-size: 5.5rem;
                @include mixins.responsive(
                font-size,
                vars.$project_category_headline_font_size
                );
            }

            & .project-category-short-description {
                line-height: 1.5;

                margin: 0 .66rem 0 .66rem;

            }
            & .projects-wrapper {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                & .project-wrapper {
                    display: none;
                    &.visible {
                        display: block;
                        visibility: visible;
                    }
                    @include mixins.responsive-fixed-flex(
                    vars.$project_wrapper_widths
                    );
                    @include mixins.responsive(
                    padding,
                    vars.$project_wrapper_paddings
                    );
                    & .project {
                        position: relative;
                        height: 100%;
                        background-color: map-get(vars.$colors, "dark-grey");
                        & .project-image-link {
                            display: block;
                            position: relative;
                            width: 100%;
                            padding-bottom: 56.25%;
                            & img {
                                display: block;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                margin-top:5px;
                                transform: translate(-50%, -50%);
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        & .project-text {
                            @include mixins.responsive(
                            padding,
                            vars.$project_text_paddings
                            );
                            & .project-title-link {
                                text-decoration: none;
                                & .project-title {
                                    font-family: Bebas Neue;
                                    margin: 0;
                                    @include mixins.responsive(
                                    font-size,
                                    vars.$project_title_font_size
                                    );
                                }
                            }
                            & .project-subtitle {
                            }
                            & .project-summary {
                            }
                        }
                        & .project-dates-link {
                            position: absolute;
                            display: block;
                            right: 0.5rem;
                            bottom: 0.5rem;
                            @include mixins.responsive(
                            height,
                            vars.$project_dates_link_height
                            );
                            & svg {
                                display: block;
                                // width: auto;
                                @include mixins.responsive(
                                height,
                                vars.$project_dates_link_height
                                );
                                @include mixins.responsive(
                                width,
                                vars.$project_dates_link_width
                                );

                                fill: map-get(vars.$colors, "white");
                                &  .letter {
                                    fill: none;
                                    stroke: map-get(vars.$colors, "white");
                                }
                            }}

                            & .project-dates-info {
                                position: absolute;
                                display: block;
                                right: 0.5rem;
                                bottom: 0.66rem;
                                font-style: italic;
                                font-weight: 200;
                                color: map-get(vars.$colors, "white");
                                transform: rotate(-10deg);
                                 border: map-get(vars.$colors, "white") .05rem solid;
                    padding:0.2rem;
                            }
                            &.description {
                                position: relative;
                                display: flex;
                                align-items: center;
                                & .category-description {
                                    @include mixins.responsive(
                                    padding,
                                    vars.$category_description_padding
                                    );
                                }
                                & .category-description-symbol {
                                    position: absolute;
                                    @include mixins.responsive(
                                    display,
                                    vars.$category_description_symbol_display
                                    );
                                }
                            }
                        }
                    }
                    & .show-more-projects {
                        border: none;
                        margin-top: 1rem;
                        padding: 0.5rem 2rem;
                        font-family: Poiret One;
                        font-weight: 600;
                        cursor: pointer;
                        &.hidden {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .single-quotation-wrapper {
        @include mixins.responsive(padding, vars.$single_quotation_wrapper_padding);
        padding: 4rem 0;
         @each $color_name, $color_value in vars.$colors {
                &.background-color-#{$color_name} {
                    background-color: $color_value;
                }
            }
         
        /*background-color: map-get(vars.$colors, "light-grey");*/
        text-align: center;
        & .single-quotation {
           
            @include mixins.responsive(
            font-size,
            vars.$single_quotation_wrapper_font_size
            );
            font-family: Poiret One;
            color: map-get(vars.$colors, "black");
        }
    }

    .support-wrapper {
        /*background-color: map-get(vars.$colors, "black");*/
         @each $color_name, $color_value in vars.$colors {
                &.background-color-#{$color_name} {
                    background-color: $color_value;
                }
            }
        & .support {
            /*background-color: map-get(vars.$colors, "white");*/
            @include mixins.responsive(padding, vars.$support_padding);
            display: flex;
            flex-wrap: wrap;
            & .support-text {
                margin-bottom: 2rem;
                @include mixins.responsive-fixed-flex(vars.$support_text_widths);
                
                & h2 {
                    margin-top: 0;
                    font-family: Bebas Neue;
                    font-weight: 600;
                    font-size: 2rem;
                }
                & code {
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    font-family: Raleway;
                    color: map-get(vars.$colors, "white");
                    line-height: 1.5;
                    margin: 1rem 0;
                    &:after {
                        content: "";
                        position: absolute;
                        left: -2rem;
                        right: -2rem;
                        top: -1rem;
                        bottom: -1rem;
                        background-color: map-get(vars.$colors, "red");
                        z-index: -1;
                    }
                }
                & h3 {
                    font-size: 1.5rem;
                    margin-bottom: 0;
                }
            }
            & .support-images {
                @include mixins.responsive-fixed-flex(vars.$support_images_widths);
                & img {
                    display: block;
                    width: 90%;
                    margin: 0 auto;
                    &:nth-child(1) {
                        @include mixins.responsive(
                        display,
                        vars.$support_images_first_image_display
                        );
                    }
                    &:nth-child(2) {
                        @include mixins.responsive(
                        display,
                        vars.$support_images_second_image_display
                        );
                    }
                }
            }
        }
    }

    .quotations-slider-wrapper {
        background-color: map-get(vars.$colors, "black");
        & .quotations-slider {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & .slides {
                position: relative;
                @include mixins.responsive(
                width,
                vars.$home_quotations_slider_slides_widths
                );
                @include mixins.responsive(height, vars.$home_quotations_slides_heights);
                background-color: map-get(vars.$colors, "black");
                overflow: hidden;
                & .slide {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: map-get(vars.$colors, "black");
                    color: map-get(vars.$colors, "light-grey");
                    text-align: center;
                    font-family: Poiret One;
                    overflow: hidden;
                    transform: translateX(100%);
                    transition: transform 0.5s ease-in;
                    &:last-child {
                        transform: translateX(-100%);
                    }
                    &:first-child {
                        transform: translateX(0);
                    }
                    & .quotation {
                        margin-bottom: 0.5rem;
                        @include mixins.responsive(
                        font-size,
                        vars.$home_quotations_slider_quotation_font_size
                        );
                    }
                    & .author {
                        @include mixins.responsive(
                        font-size,
                        vars.$home_quotations_slider_author_font_size
                        );
                    }
                }
            }
            & .next-slide-button {
                z-index: 4;
                @include mixins.responsive(
                width,
                vars.$home_quotations_slider_next_slide_button_widths
                );
                cursor: pointer;
                transition: transform  .7s ease-in-out;
                & svg {
                    display: block;
                    width: 100%;
                    height: auto;
                    fill: map-get(vars.$colors, "white");
                }
            }
        }
    }

    .supporters-wrapper {
        @include mixins.responsive(padding, vars.$supporters_wrapper_padding);
        background-color: map-get(vars.$colors, "white");
        & .supporters {
            & .supporters-text {
                margin: 0 auto;
                max-width: 1000px;
                text-align: center;
                font-family: Poiret One;
                @include mixins.responsive(
                font-size,
                vars.$supporters_text_font_size
                );
            }
            & .supporters-logos {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @include mixins.responsive(margin, vars.$supporters_logos_margins);
                max-width: 1100px;
                & .supporter-logo {
                    /*display: block;*/
                    @include mixins.responsive-fixed-flex(
                    vars.$supporter_logo_widths
                    );
                    @include mixins.responsive(
                    padding,
                    vars.$supporter_logo_paddings
                    );
                    & img {
                        display: block;
/*                        height:75px;
                        width: auto;*/
                        max-width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
