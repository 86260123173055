@charset "UTF-8";
/*$pressblock_faq_wrapper_margins: (
"xs": 2rem 0 2rem 0,
"sm": 2rem 0 2rem 0,
"md": 2rem 0 2rem 0,
"lg": 0rem 0 4rem 0,
"xl": 0rem 0 4rem 0,
"xxl": 0rem 0 4rem 0,
); */
/* $faq_wrapper_margins: (
"xs": 2rem 0 2rem 0,
"sm": 2rem 0 2rem 0,
"md": 2rem 0 2rem 0,
"lg": 0rem 0 4rem 0,
"xl": 0rem 0 4rem 0,
"xxl": 0rem 0 4rem 0,
);*/
/*AL*/
.banner-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 0) {
  .banner-slider {
    height: calc(100vh - 89px);
  }
}
@media (min-width: 576px) {
  .banner-slider {
    height: calc(100vh - 89px);
  }
}
@media (min-width: 768px) {
  .banner-slider {
    height: calc(100vh - 89px);
  }
}
@media (min-width: 992px) {
  .banner-slider {
    height: calc(56vw - 123px);
  }
}
@media (min-width: 1200px) {
  .banner-slider {
    height: calc(56vw - 133px);
  }
}
@media (min-width: 1400px) {
  .banner-slider {
    height: calc(56vw - 133px);
  }
}
@media (min-width: 0) {
  .banner-slider {
    max-height: calc(100vh - 89px);
  }
}
@media (min-width: 576px) {
  .banner-slider {
    max-height: calc(100vh - 89px);
  }
}
@media (min-width: 768px) {
  .banner-slider {
    max-height: calc(100vh - 89px);
  }
}
@media (min-width: 992px) {
  .banner-slider {
    max-height: calc(100vh - 123px);
  }
}
@media (min-width: 1200px) {
  .banner-slider {
    max-height: calc(100vh - 133px);
  }
}
@media (min-width: 1400px) {
  .banner-slider {
    max-height: calc(100vh - 133px);
  }
}
@media (orientation: portrait) {
  .banner-slider {
    min-height: 500px;
  }
}
.banner-slider .slides {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.banner-slider .slides .slide {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}
.banner-slider .slides .slide:last-child {
  transform: translateX(-100%);
}
.banner-slider .slides .slide:first-child {
  transform: translateX(0);
}
.banner-slider .slides .slide .slide-link {
  display: block;
  width: 100%;
  height: 100%;
}
.banner-slider .slides .slide .slider-image {
  display: block;
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.banner-slider .slides .slide .slider-image img {
  display: block;
}
.banner-slider .slides .slide .slider-text {
  position: absolute;
  /*color: map-get(vars.$colors, "light-grey");*/
  color: #333;
  /*background-color: rgba(map-get(vars.$colors, "black"), 0.5);*/
  background-color: rgba(255, 255, 255, 0.88);
  padding: 0.5rem 1rem;
}
@media (min-width: 0) {
  .banner-slider .slides .slide .slider-text {
    font-size: 1.25rem;
  }
}
@media (min-width: 576px) {
  .banner-slider .slides .slide .slider-text {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .banner-slider .slides .slide .slider-text {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .banner-slider .slides .slide .slider-text {
    font-size: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .banner-slider .slides .slide .slider-text {
    font-size: 2rem;
  }
}
@media (min-width: 1400px) {
  .banner-slider .slides .slide .slider-text {
    font-size: 2rem;
  }
}
.banner-slider .slides .slide .slider-text p {
  margin: 0;
}
.banner-slider .slides .slide .slider-text a {
  color: #ddd;
}
.banner-slider .slides .slide .slider-text a:hover, .banner-slider .slides .slide .slider-text a:focus {
  color: #fff;
}
.banner-slider .slides .slide .slider-text.position-topleft {
  top: 38.2%;
  /*left: 2rem;*/
  left: 38.2%;
  transform: translate(-50%, -50%);
}
.banner-slider .slides .slide .slider-text.position-topright {
  top: 38.2%;
  /*right: 2rem;*/
  left: 62.8%;
  transform: translate(-50%, -50%);
}
.banner-slider .slides .slide .slider-text.position-bottomright {
  bottom: 24%;
  left: 62.8%;
  transform: translate(-50%, -50%);
}
.banner-slider .slides .slide .slider-text.position-bottomleft {
  bottom: 24%;
  left: 38.2%;
  transform: translate(-50%, -50%);
}
.banner-slider .slides .slide .slider-text.position-center {
  top: 62.8%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner-slider .slides .slide .slider-text.position-middleleft {
  top: 62.8%;
  left: 38.2%;
  transform: translate(-50%, -50%);
}
.banner-slider .slides .slide .slider-text.position-middleright {
  top: 62.8%;
  left: 62.8%;
  transform: translate(-50%, -50%);
}
.banner-slider .slide-nav {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.banner-slider .slide-nav .slide-dot {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px #ddd solid;
  margin: 0 0.25rem;
  background-color: transparent;
}
.banner-slider .slide-nav .slide-dot.active {
  background-color: #777;
}
.banner-slider .banner-text {
  position: absolute;
  z-index: 2;
  bottom: 15%;
  left: 3rem;
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 0) {
  .banner-slider .banner-text {
    font-size: 2rem;
  }
}
@media (min-width: 576px) {
  .banner-slider .banner-text {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .banner-slider .banner-text {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .banner-slider .banner-text {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .banner-slider .banner-text {
    font-size: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .banner-slider .banner-text {
    font-size: 2.5rem;
  }
}

.general-info-wrapper {
  background-color: #000;
}
.general-info-wrapper .general-info {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 0) {
  .general-info-wrapper .general-info {
    padding: 1rem;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info {
    padding: 1rem 0;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info {
    padding: 2rem 0;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info {
    padding: 2rem 0;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info {
    padding: 3rem 0;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info {
    padding: 3rem 0;
  }
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .info-block-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .info-block-wrapper {
    padding: 0.25rem 0;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    padding: 1.5rem 0.25rem 1.5rem 0.25rem;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    padding: 1.5rem 0.25rem 1.5rem 0.25rem;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    padding: 0.25rem 1.5rem 0.25rem 0;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    padding: 0.25rem 1.5rem 0.25rem 0;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .info-block-wrapper {
    padding: 0.25rem 1.5rem 0.25rem 0;
  }
}
.general-info-wrapper .general-info .info-block-wrapper .info-block {
  height: 100%;
  background-color: #ddd;
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .info-block-wrapper .info-block {
    padding: 1rem;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .info-block-wrapper .info-block {
    padding: 1rem;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .info-block-wrapper .info-block {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .info-block-wrapper .info-block {
    padding: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .info-block-wrapper .info-block {
    padding: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .info-block-wrapper .info-block {
    padding: 1.5rem;
  }
}
.general-info-wrapper .general-info .info-block-wrapper .info-block p {
  line-height: 1.35;
}
.general-info-wrapper .general-info .info-block-wrapper .info-block p:first-child {
  margin-top: 0;
}
.general-info-wrapper .general-info .info-block-wrapper .info-block p:last-child {
  margin-bottom: 0;
}
.general-info-wrapper .general-info .info-block-wrapper .info-block .info-block-button {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  color: #ddd;
}
.general-info-wrapper .general-info .info-block-wrapper .info-block .info-block-button:hover, .general-info-wrapper .general-info .info-block-wrapper .info-block .info-block-button:focus {
  color: #fff;
}
.general-info-wrapper .general-info .column-project-categories {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    height: 100px;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    height: 75px;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    height: 100px;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    height: 200px;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    height: 200px;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    height: 200px;
  }
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    padding: 0.25rem 0;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    padding: 0.25rem;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    padding: 0.25rem;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    padding: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    padding: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper {
    padding: 0.25rem;
  }
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(1) .project-category-block {
  background-color: #ffed00;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(1) .project-category-block:hover, .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(1) .project-category-block:focus {
  background-color: #fff680;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(2) .project-category-block {
  background-color: #ea6024;
  color: #ddd;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(2) .project-category-block:hover, .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(2) .project-category-block:focus {
  background-color: #ed7845;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(3) .project-category-block {
  background-color: #009ee3;
  color: #ddd;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(3) .project-category-block:hover, .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(3) .project-category-block:focus {
  background-color: #26ade7;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper:nth-child(4) .project-category-block {
  background-color: #ddd;
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
  display: block;
  text-decoration: none;
  height: 100%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poiret One;
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    padding: 1rem;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    padding: 1rem;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    padding: 2rem;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    padding: 2rem;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    padding: 2rem;
  }
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    font-size: 1.1rem;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    font-size: 1.35rem;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block {
    font-size: 1.5rem;
  }
}
.general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
  font-family: "Bebas Neue";
  text-align: center;
}
@media (min-width: 0) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
    font-size: 1.5rem;
  }
}
@media (min-width: 576px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
    font-size: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .general-info-wrapper .general-info .column-project-categories .project-category-block-wrapper .project-category-block .project-category-name {
    font-size: 2.5rem;
  }
}

.separator {
  background-color: #000;
}
.separator svg {
  width: 100%;
  height: auto;
  stroke: #fff;
  stroke-width: 2px;
}

.project-categories-wrapper .project-category-wrapper {
  color: #fff;
  /*        @include mixins.responsive(
          transform,
          vars.$project_category_wrapper_scalings
          );*/
  /*Workaround für vertikales Abstandsproblem durch scale;*/
  /*        @include mixins.responsive(
          margin-top,
          vars.$project_category_wrapper_margins
          );*/
  /******************************************************/
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper {
    padding: 3.75rem 0 1rem 0;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper {
    padding: 3.75rem 0 1.5rem 0;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper {
    padding: 3.75rem 0 2rem 0;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper {
    padding: 3.4rem 0 2.55rem 0;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper {
    padding: 3.6rem 0calc 2.7rem 0;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper {
    padding: 4rem 0 3rem 0;
  }
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments {
  color: #ffed00;
  background-color: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .project-wrapper .project.description {
  background-color: #ffed00;
  color: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .project-wrapper .project.description .category-description-symbol {
  right: 1rem;
  bottom: 1rem;
  width: 3rem;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .project-wrapper .project.description .category-description-symbol svg {
  width: 100%;
  height: auto;
  fill: black;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .project-wrapper .project .project-title {
  color: #ffed00;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .show-more-projects {
  background-color: #ffed00;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .show-more-projects:hover, .project-categories-wrapper .project-category-wrapper.project-category-wrapper-enactments .projects-wrapper .show-more-projects:focus {
  background-color: #ccbe00;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural {
  background-color: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .project-category .project-category-headline, .project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .project-category .project-category-short-description {
  text-align: right;
  color: #ea6024;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .project-wrapper .project.description {
  background-color: #ea6024;
  color: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .project-wrapper .project.description .category-description-symbol {
  left: 1rem;
  bottom: 1rem;
  width: 3rem;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .project-wrapper .project.description .category-description-symbol svg {
  width: 100%;
  height: auto;
  fill: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .project-wrapper .project .project-title, .project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .project-wrapper .project .project-subtitle, .project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .project-wrapper .project .project-summary {
  color: #ea6024;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .show-more-projects {
  background-color: #ea6024;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .show-more-projects:hover, .project-categories-wrapper .project-category-wrapper.project-category-wrapper-sociocultural .projects-wrapper .show-more-projects:focus {
  background-color: #c84a13;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation {
  background-color: #000;
  color: #009ee3;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .project-wrapper .project.description {
  background-color: #009ee3;
  color: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .project-wrapper .project.description .category-description-symbol {
  left: 1rem;
  top: 1rem;
  width: 3rem;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .project-wrapper .project.description .category-description-symbol svg {
  width: 100%;
  height: auto;
  fill: #000;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .project-wrapper .project .project-title {
  color: #009ee3;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .show-more-projects {
  background-color: #009ee3;
}
.project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .show-more-projects:hover, .project-categories-wrapper .project-category-wrapper.project-category-wrapper-culturaleducation .projects-wrapper .show-more-projects:focus {
  background-color: #007bb0;
}
.project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
  margin: 0 0.33rem 0 0.33rem;
  font-size: 5.5rem;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
    font-size: 2rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
    font-size: 3rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
    font-size: 4rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
    font-size: 5rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
    font-size: 6rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .project-category-headline {
    font-size: 6rem;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .project-category-short-description {
  line-height: 1.5;
  margin: 0 0.66rem 0 0.66rem;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
  display: none;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper.visible {
  display: block;
  visibility: visible;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    padding: 0.5rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    padding: 0.5rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    padding: 0.5rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    padding: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    padding: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper {
    padding: 0.5rem;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project {
  position: relative;
  height: 100%;
  background-color: #333;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-image-link {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-image-link img {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 5px;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text {
    padding: 1rem 1rem 3rem 1rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text {
    padding: 1rem 4.5rem 1rem 1rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text {
    padding: 1rem 4.5rem 1rem 1rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text {
    padding: 1rem 4.5rem 1rem 1rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text {
    padding: 1rem 5rem 1rem 1rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text {
    padding: 1rem 5rem 1rem 1rem;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link {
  text-decoration: none;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
  font-family: Bebas Neue;
  margin: 0;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
    font-size: 1.75rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
    font-size: 1.75rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
    font-size: 1.75rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
    font-size: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
    font-size: 2.25rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-text .project-title-link .project-title {
    font-size: 2.25rem;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
  position: absolute;
  display: block;
  right: 0.5rem;
  bottom: 0.5rem;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
    height: 2rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
    height: 2rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
    height: 2rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
    height: 2rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
    height: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link {
    height: 2.5rem;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
  display: block;
  fill: #fff;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    height: 2rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    height: 2rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    height: 2rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    height: 2rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    height: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    height: 2.5rem;
  }
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    width: 44px;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    width: 44px;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    width: 44px;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    width: 44px;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    width: 55px;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg {
    width: 55px;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-link svg .letter {
  fill: none;
  stroke: #fff;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project .project-dates-info {
  position: absolute;
  display: block;
  right: 0.5rem;
  bottom: 0.66rem;
  font-style: italic;
  font-weight: 200;
  color: #fff;
  transform: rotate(-10deg);
  border: #fff 0.05rem solid;
  padding: 0.2rem;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description {
  position: relative;
  display: flex;
  align-items: center;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description {
    padding: 0 1rem;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description {
    padding: 0 4.5rem;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description {
    padding: 0 4.5rem;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description {
    padding: 0 2rem;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description {
    padding: 0 2rem;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description {
    padding: 0 2rem;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
  position: absolute;
}
@media (min-width: 0) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
    display: none;
  }
}
@media (min-width: 576px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
    display: block;
  }
}
@media (min-width: 768px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
    display: block;
  }
}
@media (min-width: 992px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
    display: block;
  }
}
@media (min-width: 1200px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
    display: block;
  }
}
@media (min-width: 1400px) {
  .project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .project-wrapper .project.description .category-description-symbol {
    display: block;
  }
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .show-more-projects {
  border: none;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  font-family: Poiret One;
  font-weight: 600;
  cursor: pointer;
}
.project-categories-wrapper .project-category-wrapper .project-category .projects-wrapper .show-more-projects.hidden {
  display: none;
}

.single-quotation-wrapper {
  padding: 4rem 0;
  /*background-color: map-get(vars.$colors, "light-grey");*/
  text-align: center;
}
@media (min-width: 0) {
  .single-quotation-wrapper {
    padding: 2rem 0;
  }
}
@media (min-width: 576px) {
  .single-quotation-wrapper {
    padding: 3rem 0;
  }
}
@media (min-width: 768px) {
  .single-quotation-wrapper {
    padding: 3rem 0;
  }
}
@media (min-width: 992px) {
  .single-quotation-wrapper {
    padding: 4rem 0;
  }
}
@media (min-width: 1200px) {
  .single-quotation-wrapper {
    padding: 4rem 0;
  }
}
@media (min-width: 1400px) {
  .single-quotation-wrapper {
    padding: 4rem 0;
  }
}
.single-quotation-wrapper.background-color-white {
  background-color: #fff;
}
.single-quotation-wrapper.background-color-light-grey {
  background-color: #ddd;
}
.single-quotation-wrapper.background-color-medium-grey {
  background-color: #777;
}
.single-quotation-wrapper.background-color-dark-grey {
  background-color: #333;
}
.single-quotation-wrapper.background-color-black {
  background-color: #000;
}
.single-quotation-wrapper.background-color-red {
  background-color: #c41e20;
}
.single-quotation-wrapper.background-color-yellow {
  background-color: #ffed00;
}
.single-quotation-wrapper.background-color-dark-yellow {
  background-color: #baa833;
}
.single-quotation-wrapper.background-color-orange {
  background-color: #ea6024;
}
.single-quotation-wrapper.background-color-blue {
  background-color: #009ee3;
}
.single-quotation-wrapper.background-color-enactments {
  background-color: #ffed00;
}
.single-quotation-wrapper.background-color-sociocultural {
  background-color: #ea6024;
}
.single-quotation-wrapper.background-color-culturaleducation {
  background-color: #009ee3;
}
.single-quotation-wrapper .single-quotation {
  font-family: Poiret One;
  color: #000;
}
@media (min-width: 0) {
  .single-quotation-wrapper .single-quotation {
    font-size: 2rem;
  }
}
@media (min-width: 576px) {
  .single-quotation-wrapper .single-quotation {
    font-size: 3rem;
  }
}
@media (min-width: 768px) {
  .single-quotation-wrapper .single-quotation {
    font-size: 3rem;
  }
}
@media (min-width: 992px) {
  .single-quotation-wrapper .single-quotation {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .single-quotation-wrapper .single-quotation {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .single-quotation-wrapper .single-quotation {
    font-size: 4rem;
  }
}

.support-wrapper {
  /*background-color: map-get(vars.$colors, "black");*/
}
.support-wrapper.background-color-white {
  background-color: #fff;
}
.support-wrapper.background-color-light-grey {
  background-color: #ddd;
}
.support-wrapper.background-color-medium-grey {
  background-color: #777;
}
.support-wrapper.background-color-dark-grey {
  background-color: #333;
}
.support-wrapper.background-color-black {
  background-color: #000;
}
.support-wrapper.background-color-red {
  background-color: #c41e20;
}
.support-wrapper.background-color-yellow {
  background-color: #ffed00;
}
.support-wrapper.background-color-dark-yellow {
  background-color: #baa833;
}
.support-wrapper.background-color-orange {
  background-color: #ea6024;
}
.support-wrapper.background-color-blue {
  background-color: #009ee3;
}
.support-wrapper.background-color-enactments {
  background-color: #ffed00;
}
.support-wrapper.background-color-sociocultural {
  background-color: #ea6024;
}
.support-wrapper.background-color-culturaleducation {
  background-color: #009ee3;
}
.support-wrapper .support {
  /*background-color: map-get(vars.$colors, "white");*/
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 0) {
  .support-wrapper .support {
    padding: 2rem;
  }
}
@media (min-width: 576px) {
  .support-wrapper .support {
    padding: 3rem;
  }
}
@media (min-width: 768px) {
  .support-wrapper .support {
    padding: 3rem;
  }
}
@media (min-width: 992px) {
  .support-wrapper .support {
    padding: 4rem;
  }
}
@media (min-width: 1200px) {
  .support-wrapper .support {
    padding: 4rem;
  }
}
@media (min-width: 1400px) {
  .support-wrapper .support {
    padding: 4rem;
  }
}
.support-wrapper .support .support-text {
  margin-bottom: 2rem;
}
@media (min-width: 0) {
  .support-wrapper .support .support-text {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .support-wrapper .support .support-text {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .support-wrapper .support .support-text {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
@media (min-width: 992px) {
  .support-wrapper .support .support-text {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
@media (min-width: 1200px) {
  .support-wrapper .support .support-text {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
@media (min-width: 1400px) {
  .support-wrapper .support .support-text {
    flex: 0 0 66.6%;
    min-width: 66.6%;
    width: 66.6%;
    max-width: 66.6%;
  }
}
.support-wrapper .support .support-text h2 {
  margin-top: 0;
  font-family: Bebas Neue;
  font-weight: 600;
  font-size: 2rem;
}
.support-wrapper .support .support-text code {
  display: inline-block;
  position: relative;
  z-index: 1;
  font-family: Raleway;
  color: #fff;
  line-height: 1.5;
  margin: 1rem 0;
}
.support-wrapper .support .support-text code:after {
  content: "";
  position: absolute;
  left: -2rem;
  right: -2rem;
  top: -1rem;
  bottom: -1rem;
  background-color: #c41e20;
  z-index: -1;
}
.support-wrapper .support .support-text h3 {
  font-size: 1.5rem;
  margin-bottom: 0;
}
@media (min-width: 0) {
  .support-wrapper .support .support-images {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .support-wrapper .support .support-images {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .support-wrapper .support .support-images {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
@media (min-width: 992px) {
  .support-wrapper .support .support-images {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
@media (min-width: 1200px) {
  .support-wrapper .support .support-images {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
@media (min-width: 1400px) {
  .support-wrapper .support .support-images {
    flex: 0 0 33.3%;
    min-width: 33.3%;
    width: 33.3%;
    max-width: 33.3%;
  }
}
.support-wrapper .support .support-images img {
  display: block;
  width: 90%;
  margin: 0 auto;
}
@media (min-width: 0) {
  .support-wrapper .support .support-images img:nth-child(1) {
    display: none;
  }
}
@media (min-width: 576px) {
  .support-wrapper .support .support-images img:nth-child(1) {
    display: none;
  }
}
@media (min-width: 768px) {
  .support-wrapper .support .support-images img:nth-child(1) {
    display: block;
  }
}
@media (min-width: 992px) {
  .support-wrapper .support .support-images img:nth-child(1) {
    display: block;
  }
}
@media (min-width: 1200px) {
  .support-wrapper .support .support-images img:nth-child(1) {
    display: block;
  }
}
@media (min-width: 1400px) {
  .support-wrapper .support .support-images img:nth-child(1) {
    display: block;
  }
}
@media (min-width: 0) {
  .support-wrapper .support .support-images img:nth-child(2) {
    display: block;
  }
}
@media (min-width: 576px) {
  .support-wrapper .support .support-images img:nth-child(2) {
    display: block;
  }
}
@media (min-width: 768px) {
  .support-wrapper .support .support-images img:nth-child(2) {
    display: block;
  }
}
@media (min-width: 992px) {
  .support-wrapper .support .support-images img:nth-child(2) {
    display: block;
  }
}
@media (min-width: 1200px) {
  .support-wrapper .support .support-images img:nth-child(2) {
    display: none;
  }
}
@media (min-width: 1400px) {
  .support-wrapper .support .support-images img:nth-child(2) {
    display: none;
  }
}

.quotations-slider-wrapper {
  background-color: #000;
}
.quotations-slider-wrapper .quotations-slider {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quotations-slider-wrapper .quotations-slider .slides {
  position: relative;
  background-color: #000;
  overflow: hidden;
}
@media (min-width: 0) {
  .quotations-slider-wrapper .quotations-slider .slides {
    width: calc(100% - 40px);
  }
}
@media (min-width: 576px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    width: calc(100% - 60px);
  }
}
@media (min-width: 992px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    width: calc(100% - 60px);
  }
}
@media (min-width: 1200px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    width: calc(100% - 60px);
  }
}
@media (min-width: 1400px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    width: calc(100% - 60px);
  }
}
@media (min-width: 0) {
  .quotations-slider-wrapper .quotations-slider .slides {
    height: 200px;
  }
}
@media (min-width: 576px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    height: 200px;
  }
}
@media (min-width: 768px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    height: 200px;
  }
}
@media (min-width: 992px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    height: 200px;
  }
}
@media (min-width: 1200px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    height: 200px;
  }
}
@media (min-width: 1400px) {
  .quotations-slider-wrapper .quotations-slider .slides {
    height: 200px;
  }
}
.quotations-slider-wrapper .quotations-slider .slides .slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #ddd;
  text-align: center;
  font-family: Poiret One;
  overflow: hidden;
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
}
.quotations-slider-wrapper .quotations-slider .slides .slide:last-child {
  transform: translateX(-100%);
}
.quotations-slider-wrapper .quotations-slider .slides .slide:first-child {
  transform: translateX(0);
}
.quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
  margin-bottom: 0.5rem;
}
@media (min-width: 0) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
    font-size: 1.5rem;
  }
}
@media (min-width: 576px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .quotation {
    font-size: 3rem;
  }
}
@media (min-width: 0) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .author {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .author {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .author {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .author {
    font-size: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .author {
    font-size: 2rem;
  }
}
@media (min-width: 1400px) {
  .quotations-slider-wrapper .quotations-slider .slides .slide .author {
    font-size: 2rem;
  }
}
.quotations-slider-wrapper .quotations-slider .next-slide-button {
  z-index: 4;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
}
@media (min-width: 0) {
  .quotations-slider-wrapper .quotations-slider .next-slide-button {
    width: 30px;
  }
}
@media (min-width: 576px) {
  .quotations-slider-wrapper .quotations-slider .next-slide-button {
    width: 40px;
  }
}
@media (min-width: 768px) {
  .quotations-slider-wrapper .quotations-slider .next-slide-button {
    width: 50px;
  }
}
@media (min-width: 992px) {
  .quotations-slider-wrapper .quotations-slider .next-slide-button {
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quotations-slider-wrapper .quotations-slider .next-slide-button {
    width: 50px;
  }
}
@media (min-width: 1400px) {
  .quotations-slider-wrapper .quotations-slider .next-slide-button {
    width: 50px;
  }
}
.quotations-slider-wrapper .quotations-slider .next-slide-button svg {
  display: block;
  width: 100%;
  height: auto;
  fill: #fff;
}

.supporters-wrapper {
  background-color: #fff;
}
@media (min-width: 0) {
  .supporters-wrapper {
    padding: 1rem;
  }
}
@media (min-width: 576px) {
  .supporters-wrapper {
    padding: 2rem 0;
  }
}
@media (min-width: 768px) {
  .supporters-wrapper {
    padding: 2.5rem 0;
  }
}
@media (min-width: 992px) {
  .supporters-wrapper {
    padding: 3rem 0 4.5rem 0;
  }
}
@media (min-width: 1200px) {
  .supporters-wrapper {
    padding: 4rem 0 6rem 0;
  }
}
@media (min-width: 1400px) {
  .supporters-wrapper {
    padding: 4rem 0 6rem 0;
  }
}
.supporters-wrapper .supporters .supporters-text {
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
  font-family: Poiret One;
}
@media (min-width: 0) {
  .supporters-wrapper .supporters .supporters-text {
    font-size: 1.5rem;
  }
}
@media (min-width: 576px) {
  .supporters-wrapper .supporters .supporters-text {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .supporters-wrapper .supporters .supporters-text {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .supporters-wrapper .supporters .supporters-text {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .supporters-wrapper .supporters .supporters-text {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .supporters-wrapper .supporters .supporters-text {
    font-size: 3rem;
  }
}
.supporters-wrapper .supporters .supporters-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
}
@media (min-width: 0) {
  .supporters-wrapper .supporters .supporters-logos {
    margin: 2rem auto 0 auto;
  }
}
@media (min-width: 576px) {
  .supporters-wrapper .supporters .supporters-logos {
    margin: 2rem auto 0 auto;
  }
}
@media (min-width: 768px) {
  .supporters-wrapper .supporters .supporters-logos {
    margin: 2rem auto 0 auto;
  }
}
@media (min-width: 992px) {
  .supporters-wrapper .supporters .supporters-logos {
    margin: 2rem auto 0 auto;
  }
}
@media (min-width: 1200px) {
  .supporters-wrapper .supporters .supporters-logos {
    margin: 2rem auto 0 auto;
  }
}
@media (min-width: 1400px) {
  .supporters-wrapper .supporters .supporters-logos {
    margin: 2rem auto 0 auto;
  }
}
.supporters-wrapper .supporters .supporters-logos .supporter-logo {
  /*display: block;*/
}
@media (min-width: 0) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    flex: 0 0 50%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    flex: 0 0 25%;
    min-width: 25%;
    width: 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    flex: 0 0 25%;
    min-width: 25%;
    width: 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    flex: 0 0 25%;
    min-width: 25%;
    width: 25%;
    max-width: 25%;
  }
}
@media (min-width: 0) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    padding: 1rem;
  }
}
@media (min-width: 576px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    padding: 1.5rem;
  }
}
@media (min-width: 768px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    padding: 1.5rem;
  }
}
@media (min-width: 992px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    padding: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    padding: 2rem;
  }
}
@media (min-width: 1400px) {
  .supporters-wrapper .supporters .supporters-logos .supporter-logo {
    padding: 2rem;
  }
}
.supporters-wrapper .supporters .supporters-logos .supporter-logo img {
  display: block;
  /*                        height:75px;
                          width: auto;*/
  max-width: 100%;
  margin: 0 auto;
}